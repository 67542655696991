import { createRouter, createWebHashHistory } from "vue-router";
import storage from "@utils/tokenStorage";
import MMain from "@views/MMain.vue";
import MViews from "@views/MViews.vue";
import MView from "@views/MView.vue";
import MTemplates from "@views/MTemplates.vue";
import MSettings from "@views/MSettings.vue";
import TimeFrame from "./utils/enums/timeFrames";
import DateTimeUtils from "./utils/dateTime";
import { DateTime } from "luxon";
import { useFiltersStore } from "./store/modules/filters";

const verifyClusterMaxAge = async (to, from) => {
  if (!from.query.q) return;
  const { useUserStore } = await import("@root/store/modules/user");
  const { useWorkspacesStore } = await import("@root/store/modules/workspaces");

  const userStore = useUserStore();
  const workspacesStore = useWorkspacesStore();

  const maxClusterAgeStr =
    workspacesStore.currentWorkspaceConfig?.personalization?.maxClusterAge; //days
  if (!maxClusterAgeStr) return;
  const maxClusterAge = DateTimeUtils.parseFromTimeSpan(
    maxClusterAgeStr,
    DateTime.now(),
    { locale: userStore.i18nLanguage }
  );

  /** @type {import("@root/types").Monitio.URLQueryObject} */
  const query = JSON.parse(decodeURIComponent(escape(atob(from.query.q))));
  /** @type {import("@root/types.api.local").MonitioAPI.DateRestriction} */
  let computedDateRestriction;

  if (
    query.dateRestriction?.isRelative ||
    !query.dateRestriction /** Means that the DEFAULT is applied, and the deafult is always relative */
  ) {
    if (!query.dateRestriction) {
      //Apply Default here
      computedDateRestriction = DateTimeUtils.getTimeFrame(
        TimeFrame.Last7Days,
        userStore.timeZone
      );
    } else if (query.dateRestriction.timeFrame == TimeFrame.SinceSnapshot) {
      const { useReportsStore } = await import("@root/store/modules/reports");
      const reportStore = useReportsStore();
      const report = reportStore.getById(query.dateRestriction?.reportId);
      computedDateRestriction = DateTimeUtils.getTimeFrame(
        query.dateRestriction.timeFrame,
        userStore.timeZone,
        null,
        report
      );
    } else if (
      query.dateRestriction.timeFrame == TimeFrame.All.value ||
      query.dateRestriction.timeFrame == TimeFrame.Latest.value
    ) {
      computedDateRestriction = DateTimeUtils.getTimeFrame(
        query.dateRestriction.timeFrame,
        userStore.timeZone,
        workspacesStore.currentWorkspaceConfig.documentDateRange
      );
    } else {
      computedDateRestriction = DateTimeUtils.getTimeFrame(
        query.dateRestriction.timeFrame,
        userStore.timeZone
      );
    }
    computedDateRestriction.isRelative = true;
  } else {
    computedDateRestriction = {
      ...query.dateRestriction,
      start: DateTime.fromISO(query.dateRestriction.start).setZone(
        userStore.timeZone
      ),
      end: DateTime.fromISO(query.dateRestriction.end).setZone(
        userStore.timeZone
      ),
    };
  }

  if (
    computedDateRestriction.end
      .diff(computedDateRestriction.start, "days")
      .toObject().days > maxClusterAge.duration.days
  ) {
    if (computedDateRestriction.isRelative) {
      if (maxClusterAge.duration.days <= 3) {
        query.dateRestriction.timeFrame = TimeFrame.Last3days.value;
      } else if (maxClusterAge.duration.days <= 7) {
        query.dateRestriction.timeFrame = TimeFrame.Last7Days.value;
      } else if (maxClusterAge.duration.days <= 31) {
        query.dateRestriction.timeFrame = TimeFrame.LastMonth.value;
      } else if (maxClusterAge.duration.days <= 183) {
        query.dateRestriction.timeFrame = TimeFrame.Last6Months.value;
      } else if (maxClusterAge.duration.days <= 365) {
        query.dateRestriction.timeFrame = TimeFrame.ThisYear.value;
      } else {
        query.dateRestriction.timeFrame = TimeFrame.All.value;
      }
    } else {
      query.dateRestriction.end = computedDateRestriction.end;
      query.dateRestriction.start = computedDateRestriction.end
        .minus({ days: maxClusterAge.duration.days })
        .startOf("day");
    }
  }

  to.query.q = btoa(unescape(encodeURIComponent(JSON.stringify(query))));
};

const router = createRouter({
  // mode: "hash",
  // base: window._env_.VUE_APP_BASE_URL || "",
  history: createWebHashHistory(),
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("./views/MLogin.vue"),
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import("./views/MForgotPassword.vue"),
    },
    {
      path: "/change-password/:token",
      name: "change-password",
      component: () => import("./views/MChangePassword.vue"),
    },
    {
      path: "/activate-account/:token?",
      name: "activate-account",
      component: () => import("./views/MActivateAccount.vue"),
    },
    {
      path: "/tester-page",
      name: "testerpage",
      component: () => import("./views/MTesterPage.vue"),
    },
    {
      path: "/switch-workspace/:from/:workspaceId/:viewId?/:targetId?",
      name: "switch-workspace",
      meta: {},
      component: () => import("./views/MSwitchWorkspace.vue"),
    },
    {
      path: "/",
      name: "main",
      component: MMain,
      redirect: "/home",
      children: [
        {
          path: "home",
          name: "home",
          meta: { breadcrumbs: ["home"] },
          component: () => import("./views/MHome.vue"),
        },

        {
          path: "views/",
          name: "view",
          component: MView,
          redirect: "/home",
          children: [
            {
              path: "all",
              name: "views",
              meta: { breadcrumbs: ["views"] },
              component: MViews,
            },
            {
              path: ":viewId?/articles",
              name: "articles",
              meta: {
                title: "Articles",
                description: "articles",
                breadcrumbs: ["views", "view", "articles"],
              },
              component: () => import("./views/MArticles.vue"),
            },
            {
              path: ":viewId?/tiles",
              name: "tiles",
              meta: {
                title: "Tiles",
                description: "tiles",
                breadcrumbs: ["views", "view", "tiles"],
              },
              component: () => import("./views/MTiles.vue"),
              beforeEnter: [verifyClusterMaxAge],
            },
            {
              path: ":viewId?/tiles/story/:clusterId",
              name: "tiles-cluster",
              meta: {
                title: "Story",
                description: "cluster",
                breadcrumbs: ["views", "view", "tiles", "cluster"],
              },
              component: () => import("./views/MCluster.vue"),
            },
            {
              path: ":viewId/headlines",
              name: "headlines",
              meta: {
                title: "Headlines",
                description: "headlines",
                breadcrumbs: ["views", "view", "headlines"],
              },
              component: () => import("./views/MHeadlines.vue"),
              beforeEnter: [verifyClusterMaxAge],
            },
            {
              path: ":viewId?/headlines/story/:clusterId",
              name: "headlines-cluster",
              meta: {
                title: "Story",
                description: "cluster",
                breadcrumbs: ["views", "view", "headlines", "cluster"],
              },
              component: () => import("./views/MCluster.vue"),
            },
            {
              path: ":viewId/trending-entities",
              name: "trending-entities",
              meta: {
                title: "Trending Entities",
                description: "trending-entities",
                breadcrumbs: ["views", "view", "trending-entities"],
              },
              component: () => import("./views/MTrendingEntities.vue"),
            },
            {
              path: ":viewId/entity-network",
              name: "entity-network",
              meta: {
                title: "Entity Network",
                description: "entity-network",
                breadcrumbs: ["views", "view", "entity-network"],
              },
              component: () =>
                import("./views/EntityNetwork/MEntityNetwork.vue"),
            },
            {
              path: ":viewId?/saved-articles",
              name: "saved",
              meta: {
                title: "Saved",
                description: "saved",
                breadcrumbs: ["views", "view", "saved"],
              },
              component: () => import("./views/MSaved.vue"),
            },
            {
              path: ":viewId/dashboards",
              name: "dashboards",
              meta: {
                description: "dashboards",
                breadcrumbs: ["views", "view", "dashboards"],
              },
              component: () => import("./views/MDashboards.vue"),
            },
            {
              path: ":viewId?/dashboards/empty",
              name: "dashboards-empty",
              meta: {
                description: "dashboard",
                breadcrumbs: ["views", "view", "dashboards", "emptyDashboards"],
              },
              component: () => import("./views/MDashboard.vue"),
            },
            {
              path: ":viewId?/dashboards/:dashboardId/not-found",
              name: "dashboard-not-found",
              meta: {
                description: "dashboard",
                breadcrumbs: ["views", "view", "dashboards", "dashboard"],
              },
              component: () => import("./views/MNotFound.vue"),
            },
            {
              path: ":viewId/dashboards/:dashboardId",
              name: "dashboard",
              meta: {
                description: "dashboard",
                breadcrumbs: ["views", "view", "dashboards", "dashboard"],
              },
              component: () => import("./views/MDashboard.vue"),
            },
            {
              path: ":viewId/reports",
              name: "reports",
              meta: {
                description: "reports",
                breadcrumbs: ["views", "view", "reports"],
              },
              component: () => import("./views/MReports.vue"),
            },
            {
              path: ":viewId?/report/empty",
              name: "reports-empty",
              meta: {
                description: "report",
                breadcrumbs: ["views", "view", "reports", "emptyReports"],
              },
              component: () => import("./views/MReport.vue"),
            },
            {
              path: ":viewId?/reports/:reportId/not-found",
              name: "report-not-found",
              meta: {
                description: "report",
                breadcrumbs: ["views", "view", "reports", "report"],
              },
              component: () => import("./views/MNotFound.vue"),
            },
            {
              path: ":viewId/reports/:reportId",
              name: "report",
              meta: {
                title: "Report",
                description: "report",
                breadcrumbs: ["views", "view", "reports", "report"],
              },
              component: () => import("./views/MReport.vue"),
            },
            {
              path: ":viewId?/article/:articleId",
              name: "article",
              meta: {
                title: "Article",
                description: "article",
                breadcrumbs: ["views", "view", "articles", "article"],
              },
              component: () => import("./views/MArticle.vue"),
            },
            {
              path: ":viewId?/article/:articleId/redirect",
              name: "article-redirect",
              meta: {
                title: "Article",
                description: "article",
                breadcrumbs: ["views", "view", "article-redirect"],
              },
              component: () => import("./views/MRedirectPage.vue"),
            },
            {
              path: ":viewId/manage-filters",
              name: "manageFilters",
              meta: { breadcrumbs: ["views", "view", "manageFilters"] },
              component: () => import("./views/MManageFilters.vue"),
            },
            {
              path: ":viewId/create-view/:tempViewId/manage-filters",
              name: "createManageFilters",
              meta: {
                breadcrumbs: ["views", "view", "createManageFilters"],
              },
              component: () => import("./views/MManageFilters.vue"),
            },
          ].filter((x) => x != null),
        },
        {
          path: "templates/",
          name: "templates",
          meta: { breadcrumbs: ["templates"] },
          component: MTemplates,

          redirect: "templates/dashboards",
          children: [
            {
              path: "dashboards/",
              name: "dashboardsTemplates",
              meta: {
                breadcrumbs: ["templates", "dashboardsTemplates"],
              },
              component: () => import("./views/MDashboards.vue"),
            },
            {
              path: "dashboards/:dashboardId/builder",
              name: "dashboardBuilder",
              meta: {
                breadcrumbs: ["templates", "dashboardsTemplates", "builder"],
              },
              component: () => import("./views/MDashboard.vue"),
            },
            {
              path: "reports/",
              name: "reportsTemplates",
              meta: { breadcrumbs: ["templates", "reportsTemplates"] },
              component: () => import("./views/MReportsTemplates.vue"),
            },
            {
              path: "reports/:templateId/builder",
              name: "reportBuilder",
              meta: {
                breadcrumbs: ["templates", "reportsTemplates", "builder"],
              },
              component: () => import("./views/MTemplatesReport.vue"),
            },
          ],
        },
        {
          path: "settings/",
          name: "settings",
          meta: { breadcrumbs: ["settings"] },
          component: MSettings,
          redirect: "/settings/general/account",
          children: [
            {
              path: "general/",
              name: "general",
              meta: { breadcrumbs: ["settings", "general"] },
              children: [
                {
                  path: "account",
                  name: "account",
                  meta: { breadcrumbs: ["settings", "general", "account"] },
                  component: () => import("./views/MAccount.vue"),
                },
                {
                  path: "languages",
                  name: "languages",
                  meta: { breadcrumbs: ["settings", "general", "languages"] },
                  component: () => import("./views/MLanguages.vue"),
                },
                {
                  path: "tags",
                  name: "tags",
                  meta: { breadcrumbs: ["settings", "general", "tags"] },
                  component: () => import("./views/MTags.vue"),
                },
                {
                  path: "entities",
                  name: "entities",
                  meta: { breadcrumbs: ["settings", "general", "entities"] },
                  component: () => import("./views/MEntityRules.vue"),
                },
              ],
            },
            {
              path: "workspace/",
              name: "workspace",
              meta: { breadcrumbs: ["settings", "workspace"] },
              children: [
                // {
                //   path: "teams",
                //   name: "teams",
                //   meta: { breadcrumbs: ["settings", "workspace", "teams"] },
                //   component: () => import("./views/MTeams.vue"),
                // },
                {
                  path: "filters",
                  name: "filters",
                  meta: { breadcrumbs: ["settings", "workspace", "filters"] },
                  component: () => import("./views/MFilters.vue"),
                },
              ],
            },
            {
              path: "impersonate",
              name: "impersonate",
              meta: { breadcrumbs: ["settings", "impersonate"] },
              component: () => import("./views/MImpersonate.vue"),
            },
          ],
        },
      ],
    },
    { path: "/:pathMatch(.*)*", redirect: "/" },
    //PUBLIC PAGES
    {
      path: "/snapshotviewer/:snapshotId/:format",
      name: "open-snapshot",
      component: () => import("./views/MOpenSnapshotViewer.vue"),
    },
    {
      path: "/unsubscribe/:reportId/:reportData",
      name: "unsubscribe",
      component: () => import("./views/MUnsubscribe.vue"),
    },
  ],
});

router.beforeEach((to, from) => {
  console.log(to, from);

  //  redirect to login page if not logged in and trying to access a restricted
  const publicRoutes = [
    "login",
    "forgot-password",
    "change-password",
    "activate-account",
    "open-snapshot",
    "unsubscribe",
  ];

  const authRequired = !publicRoutes.includes(to.name);
  if (!authRequired) return true;

  try {
    if (storage.jwtToken.token) {
      //Navigation will happen here
      /** @description Maintain filters from one route to another */
      let filtersKept = false;
      if (from.query.q && !to.query.q) {
        /** @type {import("@root/types").Monitio.URLQueryObject} */
        const query = JSON.parse(
          decodeURIComponent(escape(atob(from.query.q)))
        );
        if (query.clear) return; // Clear is true. Its safe to NOT keep filters from one route to another
        filtersKept = true;
        if (from.name.includes("cluster")) {
          delete query.contextualFilters;
          delete query.centerNode;
        }
        if (
          to.name != "report" &&
          query.dateRestriction?.timeFrame == TimeFrame.SinceSnapshot.value
        ) {
          query.dateRestriction.timeFrame = TimeFrame.Last7Days.value;
        }
        /*  from.query.q = btoa(
          unescape(encodeURIComponent(JSON.stringify(query)))
        ); */
        to.query.q = btoa(unescape(encodeURIComponent(JSON.stringify(query))));
      }

      if (filtersKept) return to;
      else {
        /**
         * Only update the store when there's a actual navigation and not a redirect only!
         *
         * Important piece of code that keeps the in memory string synbcronized with what the user
         * sees in the URL
         */
        const filtersStore = useFiltersStore();
        console.debug("router is updating in memory query to " + to.query.q);
        filtersStore.queryObjectRaw = to.query.q;
        filtersStore.currentViewId = to.params.viewId;
        return true;
      }
    }
    throw new Error("Security exception. Please log in again.");
  } catch (e) {
    console.error(e);
    return { path: "/login" };
  }
});

export default router;
